import { AccountDto } from './account.dto';
import { Account, SettingsAtsName } from './account.model';
import { LegalEntity } from '@mkp/legal-entity/data-access';

// just in case we find an unknown value, should not happen
const DEFAULT_ATS = 'INTERNAL';

export const mapAccountDtoToAccount = (dto: AccountDto, legalEntity: LegalEntity): Account => ({
  id: dto.id,
  name: dto.name,
  legalEntity,
  createdAt: new Date(dto.createdAt),
  settings: {
    ats: {
      name: getAtsName(dto.settings.ats.name),
    },
  },
  computed: {
    canCreateUser: !!dto._links.createAccountMembership,
  },
  _version: dto._version,
});

const getAtsName = (atsName: string): SettingsAtsName => {
  if (!isAtsName(atsName)) {
    console.error(`Unknown ATS: ${atsName}`);
    return DEFAULT_ATS;
  }

  return atsName;
};
const isAtsName = (atsName: string): atsName is SettingsAtsName =>
  ['INTERNAL', 'EXTERNAL_URL', 'KOMBO'].includes(atsName);
