import { AppConfig } from '@app/config/app.config';
import { monthDiff } from '@app/shared/helpers/utils.helpers';
import { CreditRedemption } from '@mkp/shared/data-access';
import { VacancyFormMode } from '@mkp/vacancy/util-vacancy-form';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import {
  selectRouteData,
  selectRouteParam,
  selectRouteQueryParam,
} from '@store/selectors/router.selectors';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { vacancyStore } from '@vacancy/store/reducers';
import { selectAccountEntities } from 'libs/account/state/src/lib/account/account.selectors';

export const {
  selectLoading: selectVacancyIsLoading,
  selectLoaded,
  selectTotalCount,
  selectLinks,
  selectFilter,
  selectError,
  selectEntities: selectVacancyEntities,
  selectSelectedEntity: selectSelectedVacancy,
  selectFeatureState,
} = vacancyStore.selectors;

/**
 * @description get vacancy id saved on store
 */
export const selectSelectedVacancyId = createSelector(
  selectSelectedVacancy,
  (vacancy) => vacancy?.id
);

// TODO: replace with the one from the featureState adapter
export const getVacancyById = (id: string) =>
  createSelector(selectVacancyEntities, (entities) => {
    return entities[id];
  });

export const selectPromotionGuardViewModel = createSelector(
  selectVacancyIsLoading,
  selectError,
  selectSelectedVacancyId,
  (loading, error, selectedId) => ({ loading, error, selectedId })
);

export const selectVacancyFormMode: MemoizedSelector<object, VacancyFormMode | null> =
  createSelector(selectRouteData, (data) => {
    const action = data['action'];
    return Object.values(VacancyFormMode).includes(action) ? action : null;
  });

export const selectVacancyIdFromRoute = createSelector(
  selectRouteParam('id'),
  selectRouteQueryParam('vacancy'),
  (idFromParams, idFromQueryParams) => idFromParams ?? idFromQueryParams
);

export const selectVacancyFromRoute = createSelector(
  selectVacancyIdFromRoute,
  selectVacancyEntities,
  (vacancyId, vacancyEntities) => (vacancyId ? vacancyEntities[vacancyId] : undefined)
);


export const selectAccountFromVacancyRoute = createSelector(
  selectVacancyFromRoute,
  selectAccountEntities,
  (vacancy, accounts) => (vacancy ? accounts[vacancy?.accountId] : undefined)
);

export const selectList = createSelector(
  selectFeatureState,
  selectVacancyEntities,
  ({ list }, vacancies) => list.map((userId) => vacancies[userId]).filter(Boolean) as Vacancy[]
);

export const selectVacanciesByIds = (vacancyIds: Vacancy['id'][]) =>
  createSelector(selectVacancyEntities, (vacancies) =>
    vacancyIds.map((vacancyId) => vacancies[vacancyId]).filter(Boolean)
  );

const selectProps = <
  T extends keyof Pick<
    CreditRedemption,
    'jobHitCount' | 'jobApplicationSentCount' | 'jobApplicationStartCount'
  >,
>(
  props: T
) =>
  createSelector(selectVacancyFromRoute, (entity) => {
    // aggregate events
    const creditsRedemptionsArray: CreditRedemption[] = entity?._embedded.creditRedemptions ?? [];
    const gap: number = AppConfig.creditRedemptionsGap; //;

    return aggregateEvents(creditsRedemptionsArray, gap, props);
    // const activeCreditRedemption = entity?._embedded.creditRedemptions?.find(
    //   (credit) => credit._state === CreditRedemptionState.Active
    // );
    // return activeCreditRedemption?.[props] ?? null;
  });

export const selectJobHitCountByVacancyId = selectProps('jobHitCount');
export const selectJobApplicationSentCountByVacancyId = selectProps('jobApplicationSentCount');
export const selectJobApplicationStartCountByVacancyId = selectProps('jobApplicationStartCount');

// We aggregate and make sure we aggregate credits if the gap between is no more than AppConfig.creditRedemptionsGap
function aggregateEvents(
  credits: CreditRedemption[],
  gapInMonths: number,
  props: keyof CreditRedemption
): number {
  // Sort credits by date in descending order
  const sortedCredits = [...credits].sort(
    (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
  );

  return sortedCredits.reduce(
    (accumulator: number, current: CreditRedemption, index: number, array: CreditRedemption[]) => {
      if (index === 0) {
        return typeof current?.[props] === 'number' ? (current[props] as number) : 0;
      }
      const dateGap = monthDiff(new Date(current.start), new Date(array[index - 1].start));

      if (dateGap > gapInMonths) {
        array.splice(1);
        return accumulator; // Stop the reduce operation
      }

      return accumulator + (typeof current?.[props] === 'number' ? (current[props] as number) : 0); // Add the current events to the total
    },
    0
  );
}
