import { LayoutElements, LayoutInputParams } from '@mkp/layout/state';
import { ElementBuilder } from './layout-element-config-builder';
import {
  ANY_ORDER_SUMMARY,
  BO_CLAIM_COMPANY,
  CLAIM_COMPANY,
  CLAIM_MANAGEMENT_DETAILS_WITH_ID,
  CMS_PAGE,
  CREATE_VACANCY,
  EDIT_VACANCY,
  LANDING_PAGE,
  LOGIN_PAGE,
  MY_USER_PROFILE,
  ONBOARDING,
  SELECT_WORKSPACE,
  STORE_COMPARISON_TABLE,
  SUPPORT_CENTER,
  VERIFY_IDENTITY,
  urlMatchesAnyPage,
  removeQueryParams,
  PURCHASE_CREDITS,
  PURCHASE_CREDITS_COMPARISON,
} from './layout-pages.helpers';
import { User } from '@sentry/browser';

export const areLayoutElementsDifferent = (
  prev: LayoutElements,
  curr: LayoutElements,
  isMobile: boolean
) => {
  return (
    isMobile ||
    prev.isNavigationCollapsed === undefined ||
    prev.hasNavigation !== curr.hasNavigation ||
    prev.hasBorders !== curr.hasBorders ||
    prev.hasFooter !== curr.hasFooter ||
    prev.normalDocumentFlow !== curr.normalDocumentFlow ||
    prev.hasTopHeader !== curr.hasTopHeader
  );
};

export const getPageLayoutElements = ({
  url,
  isMobile,
  isTablet,
  user,
}: LayoutInputParams): LayoutElements => {
  const sanitizedUrl = removeQueryParams(url);
  if (urlMatchesAnyPage(PAGES_WITHOUT_NAVIGATION, sanitizedUrl)) {
    return new ElementBuilder().withTopHeader().withBorders(!isMobile).withFooter().build();
  }
  if (urlMatchesAnyPage(PUBLIC_PAGES, sanitizedUrl)) {
    return new ElementBuilder().withTopHeader().withFooter().withNormalDocumentFlow().build();
  }

  if (urlMatchesAnyPage(REG_FLOW, sanitizedUrl, [BO_CLAIM_COMPANY])) {
    return new ElementBuilder().withTopHeader().build();
  }
  return createDefaultLayoutElements(user, isMobile, isTablet);
};

function createDefaultLayoutElements(
  user: User | null | undefined,
  isMobile: boolean,
  isTablet: boolean
): LayoutElements {
  return new ElementBuilder()
    .withNavigation(!!user)
    .withNavigationCollapsed(isMobile || isTablet)
    .withBorders(!!user && !isMobile)
    .withFooter()
    .withTopHeader()
    .build();
}

const PUBLIC_PAGES = [CMS_PAGE, LANDING_PAGE, SUPPORT_CENTER];

const PAGES_WITHOUT_NAVIGATION = [
  CREATE_VACANCY,
  EDIT_VACANCY,
  ANY_ORDER_SUMMARY,
  STORE_COMPARISON_TABLE,
  PURCHASE_CREDITS,
  PURCHASE_CREDITS_COMPARISON,
  SELECT_WORKSPACE,
  MY_USER_PROFILE,
  CLAIM_MANAGEMENT_DETAILS_WITH_ID,
];

const REG_FLOW = [CLAIM_COMPANY, LOGIN_PAGE, ONBOARDING, VERIFY_IDENTITY];
