import { WalletDto } from './wallet.dto';
import { Wallet, WalletAccessRole, WalletType } from './wallet.model';
import { Account } from '@mkp/account/data-access';

type Overloaded = {
  (walletDto: WalletDto, accountId: Account['id']): Wallet;
  (walletDto: WalletDto[], accountId: Account['id']): Wallet[];
};

export const toViewModel: Overloaded = ((
  input: WalletDto | WalletDto[],
  accountId: Account['id']
): Wallet | Wallet[] => {
  if (Array.isArray(input)) {
    return input.map((wallet) => mapWallet(wallet, accountId));
  }
  return mapWallet(input, accountId);
}) as Overloaded;

const mapWallet = (walletDto: WalletDto, accountId: Account['id']): Wallet => {
  const type = getWalletType(walletDto, accountId);
  return { ...walletDto, type };
};

const getWalletType = (wallet: WalletDto, accountId: Account['id']): WalletType => {
  const userAccess = wallet.walletAccesses.find((access) => access.accountId === accountId);

  if (userAccess?.role === WalletAccessRole.Owner) {
    return wallet.walletAccesses.length === 1 ? WalletType.Private : WalletType.Shared;
  }

  return WalletType.Received;
};
