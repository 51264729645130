import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { AbTestStateModule, abTestSyncReducer } from '@mkp/ab-test/state';
import { AccountMembershipStateModule } from '@mkp/account-membership/state';
import { AccountStateModule } from '@mkp/account/state';
import { ApplicationStateModule } from '@mkp/application/state';
import { VersionStateModule } from '@mkp/core/state';
import { DebugStateModule, debugSyncReducer } from '@mkp/debug/state';
import { GdprStateModule } from '@mkp/gdpr/state';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { UserEffects } from '@user/store/effects/user.effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { effects, reducers } from './';
import * as fromSettings from './reducers/settings.reducer';
import { LayoutStateModule } from '@mkp/layout/state';
import { CompanyStateModule } from '@mkp/company/state';
import { AuthStateModule } from '@mkp/auth/state';
import { WalletStateModule } from '@mkp/wallet/state';
import { CreditStateModule } from '@mkp/credit/state';

export const localStorageSyncReducer: MetaReducer = (
  reducer: ActionReducer<Action>
): ActionReducer<Action> =>
  localStorageSync({
    keys: [fromSettings.featureKey],
    storageKeySerializer: (key) => `jobcloud.${key}`,
  })(reducer);

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [localStorageSyncReducer, debugSyncReducer, abTestSyncReducer],
      runtimeChecks: { strictActionTypeUniqueness: true },
    }),
    EffectsModule.forRoot([...effects, UserEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    AuthStateModule,
    GdprStateModule,
    environment.ngrxDevTools,
    MatDialogModule,
    DebugStateModule,
    CompanyStateModule,
    LayoutStateModule,
    AbTestStateModule,
    AccountStateModule,
    WalletStateModule,
    CreditStateModule,
    AccountMembershipStateModule,
    ApplicationStateModule,
    VersionStateModule,
  ],
})
export class RootStoreModule {}
