import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProductDisplaySwitchSharedComponent } from './product-display-switch-shared.component';

@Component({
  selector: 'mkp-product-display-button-switch-offerings',
  templateUrl: './product-display-button-switch-offerings.component.html',
  styleUrl: './product-display-button-switch-offerings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    DataTestDirective,
    InlineSVGModule,
    AsyncPipe,
    NgIf,
  ],
})
export class ProductDisplayButtonSwitchOfferingsComponent extends ProductDisplaySwitchSharedComponent {
  @Input() showTranslations = true;
}
