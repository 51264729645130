<ng-container
  *ngIf="{
    offline: offline$ | async,
    gdprInitDisplay: gdprModalService.gdprInitDisplay$ | async,
    gdprFunctional: gdprFunctional$ | async
  } as vm"
>
  <router-outlet />
  <mkp-version />
  <ui-snackbar />
  <mkp-no-network *ngIf="vm.offline" />
  <ui-gdpr-banner
    *ngIf="vm.gdprInitDisplay === 'banner'"
    (showBanner)="onShowBanner()"
    (closeBanner)="onCloseBanner()"
  />
  <ui-gdpr-zendesk-placeholder *ngIf="!vm.gdprFunctional" />
  <ng-container #debugger />
</ng-container>
