import { CreditStatusActions } from '@mkp/credit/feature-credit-status/actions';
import { PublicationHistoryActions } from '@mkp/publication/feature-publication-history/actions';
import { CreditRedemptionViewModel } from '@mkp/shared/data-access';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

interface CreditRedemptionState extends EntityState<CreditRedemptionViewModel> {
  fetchState: FetchState;
}

const adapter = createEntityAdapter<CreditRedemptionViewModel>();

export const initialCreditRedemptionState: CreditRedemptionState = adapter.getInitialState({
  fetchState: LoadingState.INIT,
});

const reducer = createReducer(
  initialCreditRedemptionState,
  on(CreditStatusActions.loadCredits, (state) => ({
    ...state,
    fetchState: LoadingState.LOADING,
  })),
  on(CreditStatusActions.loadCreditsSuccess, (state, { credits }) =>
    adapter.upsertMany(credits, {
      ...state,
      fetchState: LoadingState.LOADED,
    })
  ),
  on(CreditStatusActions.loadCreditsFailed, (state, { error }) => ({
    ...state,
    fetchState: error,
  })),
  on(PublicationHistoryActions.stopCreditSuccess, (state, creditRedeem) =>
    adapter.updateOne({ id: creditRedeem.id, changes: creditRedeem }, state)
  ),
  on(PublicationHistoryActions.refundCreditSuccess, (state, { id, credit }) => {
    const oldEntity = state?.entities[id];
    const newEntity = { ...oldEntity, _embedded: { ...oldEntity?._embedded, credit } };
    return adapter.updateOne({ id, changes: newEntity }, state);
  }),
  on(CreditStatusActions.resetCreditsSuccess, () => initialCreditRedemptionState)
);

const feature = createFeature({ name: 'creditRedemption', reducer });

const { selectAll, selectTotal } = adapter.getSelectors(feature.selectCreditRedemptionState);

export const creditRedemptionFeature = { ...feature, selectAll, selectTotal };
