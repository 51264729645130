import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { CreditResource } from '@mkp/shared/data-access';
import { creditManagementPageActions } from '@mkp/credit/actions';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { creditApiActions } from '@mkp/credit/actions';
import { CreditOverviewActions } from '@mkp/credit/feature-credit-overview/actions';
import { ProductOfferingActions } from '@mkp/product/feature-product-offering/actions';
import { productSummaryPageActions } from '@features/product-summary/store/actions';
import { PublicationStorePageActions } from '@mkp/publication/feature-publication-store/actions';
import { FeatureDashboardActions } from '@mkp/user/feature-dashboard/actions';
import { selectSelectedAccountId } from '@mkp/account/state';
import { Store } from '@ngrx/store';
import { formatISO9075 } from 'date-fns';

/**
 * @deprecated will be replaced by the new credit management
 * when credit will get mandatory wallet id.
 */
export const loadAvailableCredits = createEffect(
  (actions$ = inject(Actions), store = inject(Store), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(
        CreditOverviewActions.enter,
        ProductOfferingActions.enter,
        productSummaryPageActions.enter,
        PublicationStorePageActions.enter,
        FeatureDashboardActions.enter
      ),
      switchMap(() => store.select(selectSelectedAccountId).pipe(filter(Boolean))),
      switchMap((selectedAccountId) => {
        return creditResource
          .getWithQuery({
            limit: 400,
            filter: `account.id==${selectedAccountId};_availableCredits>0;validTo>${formatISO9075(new Date())}`,
          })
          .pipe(
            map((response) => response._embedded.results),
            map((credits) => creditApiActions.loadCreditsSucceed({ credits })),
            catchError((error) => of(creditApiActions.loadCreditsFailed({ error })))
          );
      })
    ),
  { functional: true }
);

export const loadAvailableCreditsWithWallets = createEffect(
  (actions$ = inject(Actions), store = inject(Store), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(creditManagementPageActions.enter),
      switchMap(() => store.select(selectSelectedAccountId).pipe(filter(Boolean))),
      switchMap((selectedAccountId) => {
        return creditResource
          .getWithQuery({
            limit: 400,
            filter: `wallet.walletAccesses.account.id==${selectedAccountId};_availableCredits>0;validTo>${formatISO9075(new Date())}`,
          })
          .pipe(
            map((response) => response._embedded.results),
            map((credits) => creditApiActions.loadCreditsSucceed({ credits })),
            catchError((error) => of(creditApiActions.loadCreditsFailed({ error })))
          );
      })
    ),
  { functional: true }
);

export const loadCreditTotalCount = createEffect(
  (actions$ = inject(Actions), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(creditApiActions.loadCreditTotalCount),
      switchMap(() =>
        creditResource
          .getWithQuery({
            limit: 0,
            filter: `_availableCredits>0;validTo>${formatISO9075(new Date())}`,
          })
          .pipe(
            map(({ totalCount }) => creditApiActions.loadCreditTotalCountSucceed({ totalCount })),
            catchError((error) => of(creditApiActions.loadCreditTotalCountFailed({ error })))
          )
      )
    ),
  { functional: true }
);

export const loadCreditCount = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(creditManagementPageActions.enter),
      map(() => creditApiActions.loadCreditTotalCount())
    ),
  { functional: true }
);
