import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as creditEffects from './credit.effects';
import { creditFeature } from './credit.reducer';
import { CreditResource } from '@mkp/shared/data-access';

@NgModule({
  imports: [StoreModule.forFeature(creditFeature), EffectsModule.forFeature([creditEffects])],
  providers: [CreditResource],
})
export class CreditStateModule {}
