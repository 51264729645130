import { Platform } from '../platform.model';
import { CreditDto } from './credit.dto';
import { AvailableCredits, AvailableProduct } from './credit.model';

const aggregation = (acc: AvailableProduct[], current: AvailableProduct) => {
  const found = acc.find((product) => product.type === current.type);
  found ? (found._availableCredits += current._availableCredits) : acc.push(current);
  return acc;
};

export const groupCreditQuantityPerPlatform = (credits: CreditDto[]): AvailableCredits[] =>
  Object.values(Platform)
    .map((platform) => {
      const aggregate = credits
        .filter((credit) => credit.product.type.startsWith(platform.toUpperCase()))
        .map(({ product, _availableCredits }) => ({ type: product.type, _availableCredits }))
        .reduce(aggregation, [] as AvailableProduct[]);

      return aggregate.length > 0
        ? { platform: platform.toUpperCase() as Platform, aggregate }
        : null;
    })
    .filter(Boolean) as AvailableCredits[];

export const isCreditRefundable = (credit: CreditDto): boolean =>
  Array.isArray(credit?._links?.update?.attributes.properties) &&
  credit?._links?.update?.attributes.properties.includes('state');
