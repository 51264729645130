import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreditDto } from '@mkp/shared/data-access';
import { ErrorState } from '@mkp/shared/util-state';

export const creditApiActions = createActionGroup({
  source: 'Credit/API',
  events: {
    'Load Credits': emptyProps(),
    'Load Credits Succeed': props<{ credits: CreditDto[] }>(),
    'Load Credits Failed': props<{ error: ErrorState }>(),
    // 'Available Credits Loaded Success': props<{ availableCredits: AvailableCredits[] }>(),
    // 'Available Credits Loaded Failed': props<{ error: ErrorState }>(),
    'Load Credit Total Count': emptyProps(),
    'Load Credit Total Count Succeed': props<{ totalCount: number }>(),
    'Load Credit Total Count Failed': props<{ error: ErrorState }>(),
  },
});
