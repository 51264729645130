import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { Wallet } from '@mkp/shared/data-access';
import { walletApiActions } from '@mkp/wallet/actions';

interface State extends EntityState<Wallet> {
  fetchState: FetchState<{ message: string }>;
}

const adapter: EntityAdapter<Wallet> = createEntityAdapter<Wallet>();

const initialState: State = adapter.getInitialState({
  fetchState: LoadingState.INIT,
});

const reducer = createReducer(
  initialState,
  on(
    walletApiActions.loadWallets,
    walletApiActions.addWallet,
    walletApiActions.updateWallet,
    (state) => ({
      ...state,
      fetchState: LoadingState.LOADING,
    })
  ),
  on(walletApiActions.addWalletSuccess, walletApiActions.updateWalletSuccess, (state) => ({
    ...state,
    fetchState: LoadingState.LOADED,
  })),
  on(
    walletApiActions.addWalletFailure,
    walletApiActions.updateWalletFailure,
    (state, { error }) => ({
      ...state,
      fetchState: { error },
    })
  ),
  on(walletApiActions.loadWalletsSuccess, (state, { wallets }) =>
    adapter.setAll(wallets, { ...state, fetchState: LoadingState.LOADED })
  )
);

const feature = createFeature({
  name: 'wallet',
  reducer,
});

const { selectAll } = adapter.getSelectors(feature.selectWalletState);

export const walletFeature = { ...feature, selectAll };

export { State as WalletState };
