@if (config) {
  @if (config.showHeader) {
    <mds-tab-header
      [disabled]="!!config.disabled"
      [tabs]="tabs"
      [size]="config.size ?? TabSize.Small"
      (handleClick)="selectTab($event)"
    />
  }
  <ng-content />
}
