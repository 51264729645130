import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ProductFeatureCellComponent } from '@mkp/product/feature-product-shared';
import {
  BEST_VALUE_PRODUCTS,
  Product,
  ProductCode,
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductOfferingFeatureCell,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  featureMapping,
  isProductOfferingPlatformGroup,
  isFreeProductCode,
} from '@mkp/shared/util-product';

@Component({
  selector: 'mkp-product-table-column',
  templateUrl: './product-table-column.component.html',
  styleUrl: './product-table-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, ProductFeatureCellComponent],
})
export class ProductTableColumnComponent implements OnChanges {
  @Input() product!: Product;

  @Input() platforms!: ProductOfferingPlatform[];
  @Input() benefits!: ProductOfferingBenefit[];
  @Input() activePlatformGroup!: ProductPlatformGroup;

  platformMapping: ProductOfferingFeatureCell[] = [];
  benefitMapping: ProductOfferingFeatureCell[] = [];
  isBestValue = false;

  ngOnChanges(): void {
    if (this.benefits && this.product) {
      this.benefitMapping = this.benefits.map((benefit: ProductOfferingBenefit) =>
        this.getFeatureMapping(benefit)
      );
    }
    if (this.platforms && this.product) {
      this.platformMapping = this.platforms.map((platform: ProductOfferingPlatform) =>
        this.getFeatureMapping(platform)
      );
    }
    if (this.product) {
      this.isBestValue = BEST_VALUE_PRODUCTS.includes(this.product.code as ProductCode);
    }
  }

  getFeatureMapping(feature: ProductOfferingFeature): ProductOfferingFeatureCell {
    const productFeatureId = this.product.features.find(
      ({ parent, id }) => id === feature.toString() || parent === feature.toString()
    )?.id as ProductOfferingFeature;
    const {
      parent: id = feature,
      translation = getTranslation(productFeatureId, this.product.code, feature),
    } = featureMapping[productFeatureId ?? feature];

    return {
      id,
      value: !!productFeatureId,
      translation: translation,
    } as ProductOfferingFeatureCell;
  }
}

const getTranslation = (
  productFeatureId: ProductOfferingFeature,
  productCode: ProductCode,
  feature: string
) => {
  if (
    !productFeatureId ||
    !isFreeProductCode(productCode) ||
    productCode === ProductCode.JS24FREE ||
    !isProductOfferingPlatformGroup(feature)
  ) {
    return undefined;
  }

  if (productCode === ProductCode.JOBUP24FREE) {
    return 'PRODUCT.FEATURE.CELL.COMPANY_PROFILE_ONLY';
  }

  return undefined;
};
