import { Application, ApplicationStatus } from '@mkp/application/models';
import {
  applicationApiActions,
  applicationPageActions,
  applicationStatusApiActions,
} from '@mkp/application/state/actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

export interface State extends EntityState<Application> {
  applicationStatuses: ApplicationStatus[];
}

const adapter: EntityAdapter<Application> = createEntityAdapter<Application>();

const initialState: State = adapter.getInitialState({
  applicationStatuses: [],
});

export const updateOneActions = [
  applicationApiActions.applicationRefreshSuccess,
  applicationApiActions.applicationReloadForStatusAlreadyChangedSuccess,
];

export const updateManyActions = [
  applicationApiActions.statusesChangeCompleted,
  applicationApiActions.declinationsWithoutEmailCompleted,
  applicationApiActions.declinationEmailsSendCompleted,
];

export const removeOneActions = [
  applicationApiActions.applicationDeletedSuccess,
  applicationApiActions.applicationDeletedNotFound,
  applicationApiActions.emailForDeletionSentFailureApplicationNotFound,
  applicationApiActions.statusChangeNotFound,
  applicationApiActions.applicationRefreshNotFound,
];

const reducer = createReducer(
  initialState,
  on(
    applicationStatusApiActions.applicationStatusesLoadedSuccess,
    (state, { applicationStatuses }) => ({
      ...state,
      // dedup shouldn't be necessary, it's more of a safety measure, move statuses to @ngrx/entity to handle this better
      applicationStatuses: mergeAndDedupArrays(state.applicationStatuses, applicationStatuses),
    })
  ),
  on(applicationPageActions.opened, applicationPageActions.tabChanged, (state) =>
    adapter.removeAll({
      ...state,
    })
  ),
  on(applicationApiActions.applicationsLoadedSuccess, (state, { applications }) =>
    adapter.setAll(applications, {
      ...state,
    })
  ),

  on(
    applicationApiActions.moreApplicationsLoadedSuccess,
    applicationApiActions.moreApplicationsSilentlyLoadedSuccess,
    (state, { applications }) =>
      adapter.upsertMany(applications, {
        ...state,
      })
  ),
  on(applicationApiActions.routeApplicationLoadedSuccess, (state, { application }) =>
    adapter.upsertOne(application, { ...state })
  ),
  on(...removeOneActions, (state, { applicationId }) =>
    adapter.removeOne(applicationId, { ...state })
  ),
  on(...updateOneActions, (state, { application }) =>
    adapter.updateOne({ id: application.id, changes: application }, { ...state })
  ),
  on(...updateManyActions, (state, { applications }) =>
    adapter.updateMany(
      applications.map((application) => ({ id: application.id, changes: application })),
      {
        ...state,
      }
    )
  )
);
const feature = createFeature({
  name: 'application',
  reducer,
});

const { selectAll } = adapter.getSelectors(feature.selectApplicationState);

export const applicationFeature = { ...feature, selectAll };

const mergeAndDedupArrays = (
  statuses1: ApplicationStatus[],
  statuses2: ApplicationStatus[]
): ApplicationStatus[] =>
  Object.values(
    [...statuses1, ...statuses2].reduce((acc, status) => ({ ...acc, [status.id]: status }), {})
  );

export { State as ApplicationState };
