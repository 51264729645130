import { Action, createReducer, on } from '@ngrx/store';

import { Cart } from '@cart/models/cart.model';
import { cartActions, cartApiActions } from '@cart/store/actions';
import { FetchState, LoadingState } from '@mkp/shared/util-state';

/***** state interface  ****/
export interface CartState {
  cart: Cart | null;
  notes: string;
  fetchState: FetchState<{ message: string }>;
}

/***** initial state  ****/
export const initialState: CartState = {
  cart: null,
  notes: '',
  fetchState: LoadingState.INIT,
};

const _cartReducer = createReducer(
  initialState,
  on(
    cartApiActions.createCartSuccess,
    cartApiActions.addCouponToCartSuccess,
    cartApiActions.removeCouponFromCartSuccess,
    cartApiActions.fetchCartSuccess,
    (state, { cart }) => ({
      ...state,
      cart,
      fetchState: LoadingState.LOADED,
    }
    )
  ),
  on(
    cartApiActions.createCartFailure,
    cartApiActions.fetchCartFailure,
    cartApiActions.removeCouponFromCartFailure,
    cartApiActions.addCouponToCartFailure,
    (state, { error }) => ({
      ...state,
      fetchState: { error },
    })
  ),
  on(cartActions.addCartNote, (state, { notes }) => ({
    ...state,
    notes,
  })),
  on(cartActions.cartReset, () => initialState),
  on(cartApiActions.fetchCart, cartApiActions.createCart, (state) => ({
    ...state,
    fetchState: LoadingState.LOADING,
  }))
);

export function CartReducer(state: CartState, action: Action) {
  return _cartReducer(state, action);
}
