import { CreateWalletDto, WalletDto } from './wallet.dto';
import { BaseHttpResource } from '../base-http.resource';
import { CoreListEnvelop } from '../list-envelop/core-list-envelop.model';

export class WalletResource extends BaseHttpResource<
  WalletDto,
  CoreListEnvelop<WalletDto>,
  CreateWalletDto
> {
  constructor() {
    super('wallet');
  }
}
