import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, WritableSignal } from '@angular/core';
import { environment } from '@env/environment';
import {
  Application,
  ApplicationStep,
  ApplicationTab,
  AtsMentionsIds,
  BatchError,
  EmailContent,
} from '@mkp/application/models';
import { BaseHttpResource, CoreListEnvelop, QueryParams } from '@mkp/shared/data-access';
import { catchError, finalize, forkJoin, map, Observable, of } from 'rxjs';
import { ApplicationDto } from './application.dto';
import { mapApplicationDtoToModel } from './application.mapper';

const BATCH = 10;

export interface FetchApplicationPayload {
  statusIds: string[];
  vacancyId: string;
  offset: number;
  limit: number;
  tab: ApplicationTab;
}

interface UpdateStatusBatchesPayload<T extends { applicationId: string; _version: string }> {
  applicationUpdatePayloads: T[];
  statusId: string;
  currentCount?: WritableSignal<number>;
}
type PlaceholdersPayload = Pick<Application, 'firstName' | 'lastName' | 'platform'>;

interface DeclinationEmailBatchesPayload {
  applications: Application[];
  emailContent: EmailContent;
  currentCount: WritableSignal<number>;
}

const headers = new HttpHeaders({
  maxRetry: 1,
});

@Injectable({
  providedIn: 'root',
})
export class ApplicationResource extends BaseHttpResource<
  ApplicationDto,
  CoreListEnvelop<ApplicationDto>,
  Application
> {
  protected override readonly apiUrl = environment.api.internalATS;

  constructor() {
    super('application');
  }

  fetchApplicationsByTab({
    statusIds,
    vacancyId,
    offset,
    limit,
    tab,
  }: FetchApplicationPayload): Observable<Application[]> {
    const params: QueryParams = {
      filter: getFilter({ statusIds, vacancyId }),
      offset,
      limit,
    };

    if (tab === ApplicationStep.Unsuitable) {
      params['sort'] = 'applicationStatus.step=asc;createdAt=desc';
    }

    return this.http
      .get<CoreListEnvelop<ApplicationDto>>(getApplicationUrl(this.uri), { params })
      .pipe(
        map(({ _embedded: { results: applicationDtos } }) =>
          applicationDtos.map(mapApplicationDtoToModel)
        )
      );
  }

  getCountByApplicationStatusIds(statusIds: string[], vacancyId: string): Observable<number> {
    const params: QueryParams = {
      filter: getFilter({ statusIds, vacancyId }),
      offset: 0,
      limit: 0,
    };

    return this.http
      .get<CoreListEnvelop<Application>>(getApplicationUrl(this.uri), { params })
      .pipe(
        map((applicationsResponse) => applicationsResponse.totalCount),
        catchError(() => of(0))
      );
  }

  deleteApplication(applicationId: string): Observable<void> {
    return this.http.delete<void>(`${getApplicationUrl(this.uri)}/${applicationId}`);
  }

  sendDeclinationMail(applicationId: string, emailContent: EmailContent): Observable<void> {
    return this.http.post<void>(
      `${getApplicationUrl(this.uri)}/${applicationId}/send-decline-mail`,
      {
        subject: emailContent.subject,
        body: emailContent.message,
      }
    );
  }

  private getApplicationCount(vacancyId: string): Observable<number> {
    return this.getWithQuery({
      filter: `vacancy.id==${vacancyId}`,
      limit: 0,
    }).pipe(map(({ totalCount }) => totalCount));
  }

  getApplicationCounts(vacancyIds: string[]): Observable<Record<string, number>> {
    return forkJoin(
      vacancyIds.reduce(
        (acc, vacancyId) => ({ ...acc, [vacancyId]: this.getApplicationCount(vacancyId) }),
        {}
      )
    );
  }

  checkApplicationsPresence(vacancyId: string): Observable<boolean> {
    return this.getApplicationCount(vacancyId).pipe(map((totalNumber) => totalNumber > 0));
  }

  getUpdateStatusBatches<T extends { applicationId: string; _version: string }>({
    applicationUpdatePayloads,
    statusId,
    currentCount,
  }: UpdateStatusBatchesPayload<T>): Observable<Application | BatchError>[][] {
    return divideArrayIntoBatches(applicationUpdatePayloads, BATCH).map(
      (applicationUpdatePayloadBatch) =>
        applicationUpdatePayloadBatch.map(({ applicationId, _version }) =>
          this.http
            .patch<Application>(
              `${getApplicationUrl(this.uri)}/${applicationId}`,
              {
                applicationStatusId: statusId,
                _version,
              },
              { headers }
            )
            .pipe(
              catchError((error: HttpErrorResponse) => of({ error, applicationId, statusId })),
              finalize(() =>
                // Update currentCount on both success and failure
                currentCount?.update((previousValue) => previousValue + 1)
              )
            )
        )
    );
  }

  getDeclinationMailBatches({
    applications,
    emailContent,
    currentCount,
  }: DeclinationEmailBatchesPayload): Observable<void>[][] {
    return divideArrayIntoBatches(applications, BATCH).map((declinePayloadBatch) =>
      declinePayloadBatch.map(({ id, firstName, lastName, platform }) =>
        this.http
          .post<void>(
            `${getApplicationUrl(this.uri)}/${id}/send-decline-mail`,
            {
              subject: emailContent.subject,
              body:
                applications.length > 1
                  ? getMessageWithReplacedMentions(emailContent.message, {
                      firstName,
                      lastName,
                      platform,
                    })
                  : emailContent.message,
            },
            { headers }
          )
          .pipe(
            catchError((error) => of(error)),
            finalize(() =>
              // Update currentCount on both success and failure
              currentCount?.update((previousValue) => previousValue + 1)
            )
          )
      )
    );
  }

  getTotalCountForVacancies(vacancyIds: string[]): Observable<number> {
    return this.getWithQuery({ filter: getVacanciesFilter(vacancyIds), limit: 0 }).pipe(
      map(({ totalCount }) => totalCount)
    );
  }
}

const getApplicationUrl = (uri: string): string => `${environment.api.internalATS}/${uri}`;
const getFilter = ({
  statusIds,
  vacancyId,
}: Pick<FetchApplicationPayload, 'statusIds' | 'vacancyId'>): string =>
  buildQueryString(statusIds, vacancyId);

const getVacanciesFilter = (vacancyIds: string[]): string =>
  vacancyIds.map((vacancyId) => `vacancy.id==${vacancyId}`).join(',');

const buildQueryString = (statusIds: string[], vacancyId: string): string => {
  const applicationFilter = statusIds
    .map((statusId) => `applicationStatus.id==${statusId}`)
    .join(',');
  return `${applicationFilter};vacancy.id==${vacancyId}`;
};

const getMessageWithReplacedMentions = (
  messageBody: string,
  { firstName, lastName, platform }: PlaceholdersPayload
) => {
  const placeholdersToReplace = {
    [`{{${AtsMentionsIds.FirstName}}}`]: firstName,
    [`{{${AtsMentionsIds.LastName}}}`]: lastName,
    [`{{${AtsMentionsIds.Platform}}}`]: platform ?? '',
  };
  return Object.entries(placeholdersToReplace).reduce(
    (message, [placeholder, value]) => message.replaceAll(placeholder, value),
    messageBody
  );
};

const divideArrayIntoBatches = <T>(array: T[], batch: number): T[][] =>
  array.reduce(
    (acc, element) => {
      const lastBatch = acc.at(-1) ?? [];
      const rest = acc.slice(0, -1);
      return [
        ...rest,
        ...(lastBatch.length === batch ? [lastBatch, [element]] : [[...lastBatch, element]]),
      ];
    },
    [[]] as T[][]
  );
