import { LanguageIso } from '@mkp/shared/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '@user/shared/models';

export const languageEffectsActions = createActionGroup({
  source: 'Language Effects',
  events: {
    'App Language Initialized for User': props<{ languageIso: LanguageIso }>(),
    'App Language Initialized for Visitor': props<{ languageIso: LanguageIso }>(),
    'App Language Switched for User': props<{
      updateUser: { id: string; _version: string; settings: Pick<User['settings'], 'language'> };
      notification: false;
      redirectUrl: false;
    }>(),
    'App Language Switched for Visitor': emptyProps(),
  },
});
