export type TabConfig = Partial<{
  size: TabSize;
  disabled: boolean;
  preventFirstClick: boolean;
  headerPadding: boolean;
  showHeader: boolean;
}>;

export enum TabSize {
  Large = 'large',
  Small = 'small',
}
