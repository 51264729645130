import { CreditDto, CreditViewModel } from './credit.dto';
import { isCreditRefundable } from './credit.util';

export const toViewModel = (credit: CreditDto): CreditViewModel => ({
  id: credit.id,
  externalSource: credit.externalSource,
  externalId: credit.externalId,
  product: credit.product,
  validFrom: new Date(credit.validFrom),
  validTo: new Date(credit.validTo),
  accountId: credit.accountId,
  _version: credit._version,
  quantity: credit.quantity,
  state: credit.state,
  canBeRefund: isCreditRefundable(credit),
  walletId: credit.walletId,
});
