export enum ButtonSvgIcon {
  Account = '/assets/icons/redesign/account.svg',
  AddJobAds = '/assets/icons/redesign/add-job-ads.svg',
  AddPerson = '/assets/icons/redesign/add-person.svg',
  AlertCircle = '/assets/icons/redesign/alert-circle.svg',
  AlertTriangle = '/assets/icons/redesign/alert-triangle.svg',
  ArrowDown = '/assets/icons/redesign/arrow-down.svg',
  ArrowLeft = '/assets/icons/redesign/arrow-left.svg',
  ArrowRight = '/assets/icons/redesign/arrow-right.svg',
  ArrowUp = '/assets/icons/redesign/arrow-up.svg',
  At = '/assets/icons/redesign/at.svg',
  Attach = '/assets/icons/redesign/attach.svg',
  Calendar = '/assets/icons/redesign/calendar.svg',
  CellphoneAndroid = '/assets/icons/redesign/cellphone-android.svg',
  Check = '/assets/icons/redesign/check.svg',
  CheckCircle = '/assets/icons/redesign/check-circle.svg',
  ChevronDown = '/assets/icons/redesign/chevron-down.svg',
  ChevronLeft = '/assets/icons/redesign/chevron-left.svg',
  ChevronRight = '/assets/icons/redesign/chevron-right.svg',
  ChevronUp = '/assets/icons/redesign/chevron-up.svg',
  Close = '/assets/icons/redesign/close.svg',
  CloseCircle = '/assets/icons/redesign/close-circle.svg',
  Company = '/assets/icons/redesign/company.svg',
  ContentCopy = '/assets/icons/redesign/content-copy.svg',
  Creation = '/assets/icons/redesign/creation.svg',
  CreditCard = '/assets/icons/redesign/credit-card.svg',
  Delete = '/assets/icons/redesign/delete.svg',
  Earth = '/assets/icons/redesign/earth.svg',
  Edit = '/assets/icons/redesign/edit.svg',
  Email = '/assets/icons/redesign/email.svg',
  EmailOpen = '/assets/icons/redesign/email-open.svg',
  EmoticonExcited = '/assets/icons/redesign/emoticon-excited.svg',
  EmoticonNeutral = '/assets/icons/redesign/emoticon-neutral.svg',
  EmoticonSad = '/assets/icons/redesign/emoticon-sad.svg',
  Eye = '/assets/icons/redesign/eye.svg',
  EyeOff = '/assets/icons/redesign/eye-off.svg',
  FileDocument = '/assets/icons/redesign/file-document.svg',
  FileDownload = '/assets/icons/redesign/file-download.svg',
  FileDownloadOff = '/assets/icons/redesign/file-download-off.svg',
  FileUpload = '/assets/icons/redesign/file-upload.svg',
  Filter = '/assets/icons/redesign/filter.svg',
  FlipHorizontal = '/assets/icons/redesign/flip-horizontal.svg',
  Group = '/assets/icons/redesign/group.svg',
  H1 = '/assets/icons/redesign/h1.svg',
  H2 = '/assets/icons/redesign/h2.svg',
  Heart = '/assets/icons/redesign/heart.svg',
  HelpCircle = '/assets/icons/redesign/help-circle.svg',
  Home = '/assets/icons/redesign/home.svg',
  Information = '/assets/icons/redesign/information.svg',
  JobAds = '/assets/icons/redesign/job-ads.svg',
  KeyboardDoubleArrowLeft = '/assets/icons/redesign/keyboard_double_arrow_left.svg',
  KeyboardDoubleArrowRight = '/assets/icons/redesign/keyboard_double_arrow_right.svg',
  ListBulleted = '/assets/icons/redesign/list-bulleted.svg',
  ListNumbered = '/assets/icons/redesign/list-numbered.svg',
  Location = '/assets/icons/redesign/location.svg',
  Logout = '/assets/icons/redesign/logout.svg',
  Menu = '/assets/icons/redesign/menu.svg',
  MenuClose = '/assets/icons/redesign/menu-close.svg',
  MenuDotsHorizontal = '/assets/icons/redesign/menu-dots-horizontal.svg',
  MenuDotsVertical = '/assets/icons/redesign/menu-dots-vertical.svg',
  MenuOpen = '/assets/icons/redesign/menu-open.svg',
  Minus = '/assets/icons/redesign/minus.svg',
  NotificationIndicator = '/assets/icons/redesign/notification-indicator.svg',
  PauseCircle = '/assets/icons/redesign/pause-circle.svg',
  PenEdit = '/assets/icons/redesign/pen-edit.svg',
  Phone = '/assets/icons/redesign/phone.svg',
  Plus = '/assets/icons/redesign/plus.svg',
  Print = '/assets/icons/redesign/print.svg',
  Receipt = '/assets/icons/redesign/receipt.svg',
  Redo = '/assets/icons/redesign/redo.svg',
  AddOn = '/assets/icons/redesign/add-on.svg',
  Refresh = '/assets/icons/redesign/refresh.svg',
  Reply = '/assets/icons/redesign/reply.svg',
  ReplyAll = '/assets/icons/redesign/reply-all.svg',
  RocketLaunchOutline = '/assets/icons/redesign/rocket-launch-outline.svg',
  RotateLeft = '/assets/icons/redesign/rotate-left.svg',
  RotateRight = '/assets/icons/redesign/rotate-right.svg',
  Search = '/assets/icons/redesign/search.svg',
  Send = '/assets/icons/redesign/send.svg',
  Settings = '/assets/icons/redesign/settings.svg',
  Share = '/assets/icons/redesign/share.svg',
  ShieldCheck = '/assets/icons/redesign/shield-check.svg',
  Signature = '/assets/icons/redesign/signature.svg',
  Sort = '/assets/icons/redesign/sort.svg',
  SortAscending = '/assets/icons/redesign/sort-ascending.svg',
  SortDescending = '/assets/icons/redesign/sort-descending.svg',
  SortHorizontal = '/assets/icons/redesign/sort-horizontal.svg',
  SortVertical = '/assets/icons/redesign/sort-vertical.svg',
  SortBlack = '/assets/icons/redesign/sort_black.svg',
  Star = '/assets/icons/redesign/star.svg',
  StarHalfFill = '/assets/icons/redesign/star-half-fill.svg',
  StopCircle = 'assets/icons/redesign/stop-circle-outline.svg',
  Store = '/assets/icons/redesign/store.svg',
  Team = '/assets/icons/redesign/team.svg',
  TextAlignCenter = '/assets/icons/redesign/text-align-center.svg',
  TextAlignLeft = '/assets/icons/redesign/text-align-left.svg',
  TextAlignRight = '/assets/icons/redesign/text-align-right.svg',
  TextBold = '/assets/icons/redesign/text-bold.svg',
  TextItalic = '/assets/icons/redesign/text-italic.svg',
  TextUnderlined = '/assets/icons/redesign/text_underlined.svg',
  ThumbDown = '/assets/icons/redesign/thumb-down.svg',
  ThumbUp = '/assets/icons/redesign/thumb-up.svg',
  Timer = '/assets/icons/redesign/timer.svg',
  Undo = '/assets/icons/redesign/undo.svg',
  Upgrade = '/assets/icons/redesign/upgrade.svg',
  UpsellLink = 'assets/icons/redesign/upsell-link-outline.svg',
  Video = '/assets/icons/redesign/video.svg',
}
