import { createActionGroup, props } from '@ngrx/store';
import { CreateWalletDto, UpdateWalletDto, Wallet } from '@mkp/shared/data-access';
import { Account } from '@mkp/account/data-access';

export const walletApiActions = createActionGroup({
  source: 'Wallet/API',
  events: {
    loadWallets: props<{ accountId: Account['id'] }>(),
    loadWalletsSuccess: props<{ wallets: Wallet[] }>(),
    loadWalletsFailure: props<{ error: { message: string } }>(),
    addWallet: props<{ wallet: CreateWalletDto }>(),
    addWalletSuccess: props<{ wallet: Wallet }>(),
    addWalletFailure: props<{ error: { message: string } }>(),
    updateWallet: props<{ walletId: Wallet['id']; wallet: UpdateWalletDto }>(),
    updateWalletSuccess: props<{ wallet: Wallet }>(),
    updateWalletFailure: props<{ error: { message: string } }>(),
  },
});
