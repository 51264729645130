import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActionState, ButtonWrapper } from '../../../elements';

@Component({
  selector: 'ui-feedback-footer',
  templateUrl: './feedback-footer.component.html',
  styleUrl: './feedback-footer.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackFooterComponent {
  @Input() state!: ActionState;
  @Input() linkConfig: ButtonWrapper | undefined;
  @Input() linkDataTest: string | undefined;
  @Input() primaryConfig: ButtonWrapper | undefined;
  @Input() primaryDataTest: string | undefined;
  @Input() primaryTrackingDetails = '';
  @Input() secondaryConfig: ButtonWrapper | undefined;
  @Input() secondaryDataTest: string | undefined;
  @Input() tertiaryConfig: ButtonWrapper | undefined;
  @Input() tertiaryDataTest: string | undefined;
  @Input() isLoading = false;

  @Output() handlePrimaryClick = new EventEmitter();
  @Output() handleSecondaryClick = new EventEmitter();
  @Output() handleTertiaryClick = new EventEmitter();
}
