import { UpdateStr } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { User } from '@user/shared/models';

export const readLoggedInUserSuccess = createAction(
  `[User Api] Read Logged In User Success`,
  props<{ user: User }>()
);
export const readLoggedInUserFailure = createAction(
  `[User Api] Read Logged In User Failure`,
  props<{ error: { message: string } }>()
);

export const updateUserSuccess = createAction(
  `[User Api] Update User Success`,
  props<UpdateStr<User>>()
);
export const updateUserFailure = createAction(
  `[User Api] Update User Failure`,
  props<{ error: { message: string } }>()
);

export const createUserSuccess = createAction(
  `[User Api] Create User Success`,
  props<{ user: User }>()
);
export const createUserFailure = createAction(
  `[User Api] Create User Failure`,
  props<{ error: { message: string } }>()
);

export const listUsersSuccess = createAction(
  `[User Api] List Users Success`,
  props<{ users: User[]; _links: any; filter: any; totalCount: any }>()
);
export const listUsersFailure = createAction(
  `[User Api] List Users Failure`,
  props<{ error: { message: string } }>()
);

export const loadMoreUsersSuccess = createAction(
  `[User Api] Load More Users Success`,
  props<{ users: User[]; _links: any; filter: any; totalCount: any }>()
);
export const loadMoreUsersFailure = createAction(
  `[User Api] Load More Users Failure`,
  props<{ error: { message: string } }>()
);

export const resetPasswordSuccess = createAction(`[User Api] Reset Password Success`);
export const resetPasswordFailure = createAction(
  `[User Api] Reset Password Failure`,
  props<{ error: { message: string } }>()
);
export const loadUsersByIdsSuccess = createAction(
  `[User Api] Load Users By Ids Success`,
  props<{ users: User[] }>()
);
export const loadUsersByIdsFailure = createAction(
  `[User Api] Load Users By Ids Failure`,
  props<{ error: { message: string } }>()
);
