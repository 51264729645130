import { Cart } from '@app/features/cart/models/cart.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '@user/shared/models';
import { VacancyFlow } from '@vacancy/interfaces/vacancy.flow';
import { Vacancy } from '@vacancy/models/vacancy.model';

export const productSummaryPageActions = createActionGroup({
  source: 'Product Summary Page',
  events: {
    Enter: props<{ productCode: string | undefined }>(),
    Checkout: props<{ vacancyId: string; overrideUser?: User | null; sendConfirmationEmail: boolean }>(),
    'Apply Coupon': props<{ coupon: string }>(),
    'Remove Coupon': emptyProps(),
    'Change Product': props<{ flow: VacancyFlow; vacancyId: string }>(),
    'Unverified Checkout': props<{
      vacancy: Vacancy;
      productName: string;
      cart: Cart;
    }>(),
    'Reset Platform Group Post Checkout': emptyProps(),
  },
});
