import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as walletEffects from './wallet.effects';
import { walletFeature } from './wallet.reducer';
import { WalletResource } from '@mkp/shared/data-access';

@NgModule({
  imports: [StoreModule.forFeature(walletFeature), EffectsModule.forFeature([walletEffects])],
  providers: [WalletResource],
})
export class WalletStateModule {}
