import { NgModule } from '@angular/core';
import { CartModule } from '@cart/cart.module';
import { CreditStatusModule } from '@mkp/credit/feature-credit-status';
import { VacancyStateModule } from '../vacancy/store';
import { ProductOptionsStateModule } from './store/product-options-state.module';

@NgModule({
  imports: [CreditStatusModule, CartModule, ProductOptionsStateModule, VacancyStateModule],
})
export class ProductOptionsModule {}
